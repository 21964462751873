export function getNoon(date: Date): Date {
  const d = new Date(date);
  d.setHours(12, 0);
  return d;
}

/**
 * Format a date string:
 *
 * @param date
 * @param useYesterdayTodayTomorrow
 * @param options
 * options.weekday:	"narrow", "short", "long"
 * options.era:	"narrow", "short", "long"
 * options.year:	"2-digit", "numeric"
 * options.month:	"2-digit", "numeric", "narrow", "short", "long"
 * options.day:	"2-digit", "numeric"
 * options.hour:	"2-digit", "numeric"
 * options.minute:	"2-digit", "numeric"
 * options.second:	"2-digit", "numeric"
 * options.timeZoneName:	"short", "long"
 */
export function dateString(
  date: Date,
  useYesterdayTodayTomorrow: boolean = false,
  options?: Intl.DateTimeFormatOptions
): string {
  options = options || { month: 'short', day: 'numeric', year: 'numeric' };
  if (useYesterdayTodayTomorrow) {
    const relativeString = yesterdayTodayTomorrow(date);
    if (relativeString) return relativeString;
    return dateString(date, false, options);
  }
  return new Intl.DateTimeFormat(undefined, options).format(date);
}

/**
 * Format a time or time and date string:
 * @param date
 * @param options
 * options.weekday:	"narrow", "short", "long"
 * options.era:	"narrow", "short", "long"
 * options.year:	"2-digit", "numeric"
 * options.month:	"2-digit", "numeric", "narrow", "short", "long"
 * options.day:	"2-digit", "numeric"
 * options.hour:	"2-digit", "numeric"
 * options.minute:	"2-digit", "numeric"
 * options.second:	"2-digit", "numeric"
 * options.timeZoneName:	"short", "long"
 */
export function dateTimeString(
  date: Date,
  options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit' }
) {
  return new Intl.DateTimeFormat(undefined, options).format(date);
}

export function yesterdayTodayTomorrow(date: Date): string | false {
  if (isToday(date)) return 'today';
  if (isTomorrow(date)) return 'tomorrow';
  if (isYesterday(date)) return 'yesterday';
  return false;
}

export function isToday(date: Date): boolean {
  return isNDaysAway(date, 0);
}

export function isTomorrow(date: Date): boolean {
  return isNDaysAway(date, 1);
}

export function isYesterday(date: Date): boolean {
  return isNDaysAway(date, -1);
}

export function isNDaysAway(date: Date, n: number) {
  const then = new Date();
  then.setDate(then.getDate() + n);
  return (
    date.getFullYear() === then.getFullYear() &&
    date.getMonth() === then.getMonth() &&
    date.getDate() === then.getDate()
  );
}
